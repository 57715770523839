<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="ma-1" flat elevation="0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="8" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">Transactions</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="4" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-text-field
              dense
              outlined
              label="Search"
              class="mt-6 ml-2 FontSize field_label_size field_height"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="GetAllBmsRechage"
          :no-data-text="noDataText"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
          <template v-slot:[`item.organization_name`]="{ item }">
            <div class="FontSize">{{ item.organization_name }}</div>
          </template>
          <template v-slot:[`item.payment_initiator_name`]="{ item }">
            <div class="FontSize">{{ item.payment_initiator_name }}</div>
          </template>
          <template v-slot:[`item.payment_amount`]="{ item }">
            <div class="FontSize">{{ item.payment_amount }}</div>
          </template>
          <template v-slot:[`item.order_id`]="{ item }">
            <div class="FontSize">{{ item.order_id }}</div>
          </template>
          <template v-slot:[`item.payment_completed_on`]="{ item }">
            <span class="FontSize">{{
              new Date(item.payment_completed_on * 1000).toLocaleString("en-GB")
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllRecharge } from "@/mixins/GetAllRecharge.js";
export default {
  mixins: [GetAllRecharge],
  components: {
    Overlay,
  },
  data: () => ({
    search: "",
    noDataText: "",
    TableHeight : 0,
    overlay: false,
    isLoading: false,
    GetAllBmsRechage: [],
    headers: [
      { text: "Organization", value: "organization_name" },
      { text: "Payment Initiator", value: "payment_initiator_name" },
      { text: "Payment Amount", value: "payment_amount" },
      { text: "Order Id", value: "order_id" },
      { text: "Payment Completed On", value: "payment_completed_on" },
    ],
  }),
  watch: {},
  mounted() {
    this.GetAllBMSRechargeMethod();
    this.TableHeight = window.innerHeight - 200 ;
  },
  methods: {},
};
</script>

<style>
.gradient-btn {
  background: -webkit-linear-gradient(#7b046c 40%, #bd58b0 60%);
  -webkit-text-fill-color: transparent;
}
.gradient-bg {
  background: -webkit-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -moz-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -o-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -ms-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
}
.gradient-bg2 {
  background: -webkit-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -moz-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -o-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -ms-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
}
</style>
