import { ListAllRecharges } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllRecharge = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllBmsRechage: [],
    };
  },
  methods: {
    async GetAllBMSRechargeMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListAllRecharges, {
            input: {},
          })
        );
        this.GetAllBmsRechage = result.data.ListAllRecharges.data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllBmsRechage = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
